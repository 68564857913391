import React, { useState, useEffect } from 'react';
import { message, Modal, notification, Spin } from 'antd';
import closeSvg from "../../../assets/common/close.svg";
import demoUser from "../../../assets/common/demoUser.svg";
import CustomCheckBox from "../../Forms/CheckBoxVarients/CustomCheckBox";
import SecondaryButton from '../../Buttons/SecondaryButton';
import PrimaryButton from '../../Buttons/PrimaryButton';
import ModalSection from "./ModalSection";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllInvestorApi, shareDocumentsApi } from '../../../features/dataRoomSlice';

const MoveToInvestorModal = ({ isOpen, onClose, documentList }) => {
    const [usersSelected, setUsersSelected] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [investorList, setInvestorList] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const [mainloading, setMainLoading] = useState(false)
    const dispatch = useDispatch();
    
    useEffect(() => {
        const fetchInvestors = async () => {
            setLoading(true);
            try {
                const result = await dispatch(fetchAllInvestorApi());
                if (result.payload && result.payload.users) {
                    setInvestorList(result.payload.users);
                }
            } catch (error) {
                console.error('Error fetching investors:', error);
            } finally {
                setLoading(false);
            }
        };
        
        fetchInvestors();
    }, [dispatch]);
    
    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);
    
    const userHandler = (user) => {
        const isSelected = usersSelected.some((selectedUser) => selectedUser._id === user._id);
        if (isSelected) {
            setUsersSelected(usersSelected.filter((selectedUser) => selectedUser._id !== user._id));
        } else {
            setUsersSelected([...usersSelected, user]);
        }
    };
    
    const handleOk = () => {
        setModalOpen(false);
        // onClose();
    };
    
    const handleCancel = () => {
        setModalOpen(false);
        onClose();
    };
    
    const handleShare = async () => {
        setMainLoading(true);
        try {
            const userIdList = usersSelected.map(user => user._id);
            const id = documentList._id ? documentList._id : documentList;
            const body = {
                documentId: id,
                userIdList: userIdList
            };
            await dispatch(shareDocumentsApi(body)).then((response) => {
                if(response?.meta.requestStatus === "fulfilled") {
                    // notification.info({
                    //     message: "Success",
                    //     description: "Selected users have been granted access"
                    // })
                    message.success("Selected users have been granted access")
                } else {
                    // notification.error({
                    //     message: "Error",
                    //     description: "Failed to Grant Access to the Users. Please try again."
                    // })
                    message.error("Failed to Grant Access to the Users. Please try again.")
                }
                handleOk()
            });
        } catch (error) {
            console.error('Error sharing documents:', error);
        } finally {
            setMainLoading(false);
            setModalTitle("Info")
            setModalVisible(true)
        }
    };

    const handleSubmit = async () => {
        await handleShare();
        setModalOpen(false);
        setModalTitle("Access Info");
        setModalVisible(true);
    };
    
    const handleAddInvestor = () => {
        setModalOpen(false);
        setModalTitle("Edit Investor Details");
        setModalVisible(true);
    };
    
    return (
        <>
            {modalVisible && (
                <ModalSection 
                    title={modalTitle} 
                    closeHandler={() => { 
                        setModalVisible(false); 
                        handleCancel(); 
                    }}
                    text={"File has been moved to investor dashboard"}
                />
            )}
            <Modal
                title={<span style={{ fontSize: '18px' }}>Select one investor at a time to share this folder with</span>}
                centered
                visible={modalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <button 
                    className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' 
                    onClick={handleCancel}
                >
                    <img src={closeSvg} alt="Close" />
                </button>
                <div className="px-2 flex flex-col gap-3">
                    <div className="flex flex-col gap-4 max-h-[200px] overflow-y-auto hide-scrollbar mt-5">
                        {loading ? (
                            <div className="flex justify-center items-center h-full">
                                <Spin size="large" />
                            </div>
                        ) : (
                            investorList.map((user) => (
                                <div key={user._id} className="flex w-full p-2 justify-between items-center">
                                    <div className="flex items-center justify-center flex-row gap-2">
                                        <img src={demoUser} alt="user" className="border mr-1" />
                                        <h1 className="text-[#424242] text-[16px] font-medium my-auto">{user.email}</h1>
                                    </div>
                                    <CustomCheckBox
                                        isChecked={usersSelected.some((selectedUser) => selectedUser._id === user._id)}
                                        handleOnClick={() => userHandler(user)}
                                    />
                                </div>
                            ))
                        )}
                    </div>
                    <div className="w-full flex flex-row justify-between mt-3">
                        <div>
                            <SecondaryButton text={"Add Investor"} onClick={handleAddInvestor} size="Medium" isDisabled={mainloading}/>
                        </div>
                        <div>
                            <PrimaryButton 
                                text={"Grant Access"} 
                                size="Medium" 
                                onClick={handleSubmit} 
                                isDisabled={mainloading || usersSelected.length === 0} 
                                isLoading={mainloading}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default MoveToInvestorModal;

