import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, DatePicker, Switch, Row, Col, Modal, Spin, notification } from 'antd';
import CloseButton from "../../SVG/CloseButtonSVG";
import ModalSection from "./ModalSection";
import moment from "moment";
import { addInvestorApi, createStageOfFunding, getStageOfFundingList, getDeafultStageOfFundingList } from "../../../features/dataRoomSlice";
import { useDispatch } from "react-redux";
import SecondaryButton from "../../Buttons/SecondaryButton";
import PrimaryButton from "../../Buttons/PrimaryButton";

const { Option } = Select;

const EditInvestorModal = ({ isOpen, onClose, documentList, onSecondaryClose }) => {
    const [modalOpen, setModalOpen] = useState(isOpen);
    const [customModalOpen, setCustomModalOpen] = useState(false); // State for custom modal
    const [successModal, setSuccessModal] = useState(false);
    const [inputTitle, setInputTitle] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [loadingStages, setLoadingStages] = useState(false); // State for loader
    const [stageOfFunding, setStageOfFunding] = useState([]);
    const [loading, setLoading] = useState(false)
    const [reClick, setReClick] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchStageOfFundingApis = async () => {
            if (modalOpen) {
                setLoadingStages(true);
                try {
                    const response = await dispatch(getDeafultStageOfFundingList());
                    const response2 = await dispatch(getStageOfFundingList());
                    const mergedStages = [...response?.payload, ...response2?.payload];
                    setStageOfFunding(mergedStages);
                } finally {
                    setLoadingStages(false);
                }
            }
        }
        fetchStageOfFundingApis();
    }, [modalOpen, dispatch, reClick]);

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    const handleOk = () => {
        setModalOpen(false);
        onClose();
    };

    const handleCancel = () => {
        setModalOpen(false);
        onSecondaryClose();
    };

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setLoading(true)
        const body = {
            email: values.emailAddress,
            investorName: values.investorName,
            investmentAmount: values.investmentAmount,
            stageOfFunding: selectedStage ? selectedStage : values.stageOfFunding,
            investorAccessExpiry: values.accessExpiry.format('YYYY-MM-DD'),
            canAddTeamMember: values.canAddTeamMembers,
            accessForTeam: values.accessForTeam,
            accessForInvestor: values.accessForInvestor,
        };
        console.log(body)
        if (body.email === undefined || body.email === "" || body.investorName === undefined || body.investorName === "" || body.investmentAmount === undefined || body.stageOfFunding === undefined || body.accessForInvestor === undefined || body.accessForTeam === undefined || body.investmentAmount === "") {
            notification.error({
                message: "Error",
                description: "All mandatory fields has to be filled."
            })
            setLoading(false)
            return
        }
        dispatch(addInvestorApi(body))
            .then(() => {
                setSuccessModal(true);
                setModalOpen(false);
                setLoading(false);
                handleOk();
            })
            .catch((error) => {
                notification.error({
                    message: "Error",
                    description: "Failed to add Investor"
                })
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleCustomOptionChange = (value) => {
        if (value === 'custom') {
            setCustomModalOpen(true);
        } else {
            setSelectedStage(value);
        }
    };

    const handleCustomStageSubmit = () => {
        setLoading(true)
        // const body = { stageOfFundingTitle: inputTitle };
        // dispatch(createStageOfFunding(body))
        //     .then(() => {
        //         setCustomModalOpen(false);
        //         setSelectedStage(inputTitle);
        //         form.setFieldsValue({ stageOfFunding: inputTitle });
        //     })
        //     .catch((error) => {
        //         console.error('Failed to create custom stage:', error);
        //     });
        // setLoading(false)
        // setReClick(!reClick)
    };

    return (
        <>
            {successModal && (
                <ModalSection title={"Info"} text="Investor has been successfully added" closeHandler={() => { setSuccessModal(false); setModalOpen(false) }} />
            )}
            <Modal
                title={<span style={{ fontSize: "20px" }}>Investor Details</span>}
                centered
                open={modalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <button
                    className="absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all"
                    onClick={handleCancel}
                >
                    <CloseButton />
                </button>
                <div className="max-w-lg mx-auto bg-white rounded-lg">
                    <Form
                        form={form}
                        layout="vertical"
                        name="investorForm"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            investorName: documentList?.username,
                            emailAddress: documentList?.email,
                            investmentAmount: documentList?.investmentAmount,
                            stageOfFunding: documentList?.stageOfFunding,
                            accessExpiry: moment(documentList?.investorAccessExpiry),
                            accessForInvestor: documentList?.accessForInvestors,
                            canAddTeamMembers: documentList?.canAddTeamMember,
                            accessForTeam: documentList?.accessForTeams,
                        }}
                    >
                        <Form.Item
                            label="Investor Name"
                            name="investorName"
                            className="font-medium"
                        >
                            <Input placeholder="Enter Name" className="p-2 border rounded" />
                        </Form.Item>

                        <Form.Item
                            label="Email Address"
                            name="emailAddress"
                            className="font-medium"
                        >
                            <Input placeholder="Enter your work email" className="p-2 border rounded" />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Investment Amount"
                                    name="investmentAmount"
                                    className="font-medium"
                                >
                                    <Input placeholder="0" className="p-2 border rounded" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Stage Of Funding"
                                    name="stageOfFunding"
                                    className="font-medium"
                                >
                                    <Select placeholder="Select" className="w-full" onChange={handleCustomOptionChange}>
                                        {loadingStages ? (
                                            <Option value="loading">
                                                <Spin size="small" /> Loading...
                                            </Option>
                                        ) : (
                                            <>
                                                {stageOfFunding?.map((value) => (
                                                    <Option key={value._id} value={value._id}>{value.title}</Option>
                                                ))}
                                                <Option value="custom">Add Custom</Option>
                                            </>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Access Expiry"
                                    name="accessExpiry"
                                    className="font-medium"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="DD/MM/YYYY" className="p-1 border rounded" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Access For Investor"
                                    name="accessForInvestor"
                                    className="font-medium"
                                >
                                    <Select placeholder="Select" className="w-full">
                                        <Option value="View Only">View Only</Option>
                                        <Option value="Download Only">Download Only</Option>
                                        <Option value="View & Download">{"View & Download"}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Can Add Team Members" name="canAddTeamMembers" valuePropName="checked" className="font-medium flex flex-row">
                                    <div className="flex flex-row items-center gap-2">
                                        <div className="flex items-center justify-center font-semibold text-[#9E9E9E]">No</div>
                                        <Switch className="bg-[#9E9E9E]" />
                                        <div className="flex items-center justify-center font-semibold text-[#9E9E9E]">Yes</div>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Access For Team"
                                    name="accessForTeam"
                                    className="font-medium"
                                >
                                    <Select placeholder="Select" className="w-full">
                                        <Option value="View Only">View Only</Option>
                                        <Option value="Download Only">Download Only</Option>
                                        <Option value="View & Download">{"View & Download"}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className="flex w-[100%] justify-between flex-row">
                            <Button type="default" htmlType="button" disabled={loading} onClick={handleCancel} className="border-[#9E9E9E] rounded-md text-[#9E9E9E] font-semibold">
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading} className="bg-[#372EE5] text-white font-semibold rounded-md">
                                Add
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>

            <Modal
                title="Add Custom Stage of Funding"
                centered
                open={customModalOpen}
                onOk={handleCustomStageSubmit}
                onCancel={handleCancel}
            >
                <Form
                    layout="vertical"
                    name="customStageForm"
                    onFinish={handleCustomStageSubmit}
                >
                    <Form.Item
                        label="Custom Stage Title"
                        name="customStage"
                        rules={[{ required: true, message: 'Please enter a custom stage title!' }]}
                    >
                        <Input
                            placeholder="Enter custom stage title"
                            onChange={(e) => setInputTitle(e.target.value)}
                        />
                    </Form.Item>
                </Form>
                <div className="flex justify-between items-center">
                    <SecondaryButton size="Medium" text={"Cancel"} onClick={handleCancel} isDisabled={loading}/>
                    <PrimaryButton size="Medium" text={"Add"} onClick={handleCustomStageSubmit} isLoading={loading} isDisabled={loading}/>
                </div>
            </Modal>
        </>
    );
};

export default EditInvestorModal;

