import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Progress, Button, Skeleton, Input, Empty } from "antd";
import "../index.css";
import ArchiveOutline from "../DesignSystem/SVG/ArchiveOutlineSVG"
import ArchiveRoomDashboardCard from "../DesignSystem/Cards/ArchiveRoomDashboardCard";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature";
import ModalSection from "../DesignSystem/Cards/ActionMenuOptionModal/ModalSection";
import { documentuserShareList, listDirectoryApi, updateArchiveStatusApi } from "../features/dataRoomSlice";
import { ReloadOutlined, SecurityScanOutlined } from "@ant-design/icons";
import {
    SearchOutlined,
} from "@ant-design/icons";
import { fetchArchiveFileApi } from "../features/dataRoomSlice";
import SecondaryButton from "../DesignSystem/Buttons/SecondaryButton";
import PrimaryButton from "../DesignSystem/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import ArchiveFileDataRoom from "./ArchiveFileDataRoom";

export default function ArchiveDataRoom() {
    const dispatch = useDispatch();
    const [animateOnMount, setAnimateOnMount] = useState(false);
    const [checkedCards, setCheckedCards] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selectedCardDetails, setSelectedCardDetails] = useState(null);
    const [checkedCardDetails, setCheckedCardDetails] = useState([]);
    const [keysData, setKeysData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [reClick, setReClick] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [searchVisible, setSearchVisible] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [reload, setReload] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        setFilteredData(keysData.filter((item) =>
            item.name.toLowerCase().includes(searchInput.toLowerCase())
        ));
    }, [searchInput, keysData]);

    const handleSearchToggle = () => {
        setSearchVisible(!searchVisible);
        if(searchVisible) {
            setSearchInput('')
        }
    };

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
    
                const { payload } = await dispatch(fetchArchiveFileApi(""));
    
                // Use Promise.all to wait for all directory data to be processed
                const extractedData = await Promise.all(payload?.listOfDirectories.map(async (obj) => {
                    const fileCountResponse = await dispatch(listDirectoryApi({ directory: obj.name }));
                    const fileCount = fileCountResponse.payload.listOfFiles.length;
                    const data = {
                        addFolder: "",
                        documentId: obj._id
                    }
                    const filesShareResponse = await dispatch(documentuserShareList(data))
                    console.log(filesShareResponse)
    
                    return {
                        _id: obj._id,
                        organisationId: obj.organisationId,
                        userId: obj.userId,
                        name: obj.name,
                        path: obj.path,
                        type: obj.type,
                        description: obj.description,
                        archiveStatus: obj.archiveStatus,
                        tags: obj.tags,
                        color: obj.color,
                        fileCount: fileCount,
                    };
                }));
    
                setKeysData(extractedData);
                setFilteredData(extractedData);
            } catch (error) {
                console.error("Failed to list directory", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [dispatch, reClick]);
    

    const handleCardCheckboxChange = (isChecked, cardDetails) => {
        console.log("Card Details : ", cardDetails)
        if (isChecked) {
            const updatedCheckedCards = [...checkedCardDetails, cardDetails];
            console.log("Updated Checked Cards:", updatedCheckedCards);
            setCheckedCardDetails(updatedCheckedCards);
        } else {
            const filteredCards = checkedCardDetails.filter(
                (card) => card.id !== cardDetails.id
            );
            console.log("Updated Checked Cards:", filteredCards);
            setCheckedCardDetails(filteredCards);
        }
    };

    useEffect(() => {
        const anyCardChecked = checkedCardDetails.length === 0;
        setButtonDisabled(anyCardChecked);

        setSelectedCardDetails(anyCardChecked ? null : checkedCardDetails);
        console.log("checkedCardDetails", checkedCardDetails);
    }, [checkedCardDetails]);

    // Modal
    const [modalTitle, setModalTitle] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleButtonClick = () => {
        setModalTitle("Add Folder"); // Change the index as needed
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setModalTitle(null);
    };

    console.log(keysData)

    const OnDeleteClick = async () => {
        const ids = [...new Set(checkedCardDetails.map((card) => card.id))];
        console.log("These are the id selected : ", ids)

        for (const id of ids) {
            const body = {
                documentId: id,
                archiveStatus: false
            };
            console.log(body)
            await dispatch(updateArchiveStatusApi(body));
        }

        setReClick(!reClick)
    };

    return (
        <div className="pt-[6rem] min-h-[100vh]">
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    text="Success! The folder has been unarchived and is now visible in my files."
                    closeHandler={() => {
                        setIsModalVisible(false);
                        setModalTitle("");
                        setReload(!reload)
                    }}
                />
            )}
            <div className={`w-[100%] ${animateOnMount && "fade-in-out"}`}>
                {
                    <div className="mx-auto">
                        <DescribeFeature
                            text={"Archive Folders"}
                            description={"Easily access and manage archived files. Archived files are stored separately from active files, providing a clutter-free view while still allowing quick retrieval when needed."}
                        />
                        <div className="flex justify-between items-center mt-6">
                            <div className="flex flex-row gap-3">
                                <Button
                                    type="secondary"
                                    danger
                                    className={`transition-none hover:opacity-75 ${buttonDisabled ? "bg-[#D8D8D8] border-2 border-[#D8D8D8] rounded-md text-white font-semibold px-3 py-4" : "border-2 border-[#372EE5] rounded-md py-4 px-3 text-[#372EE5] font-semibold"}`}
                                    icon={<ArchiveOutline className="transition-all" color={buttonDisabled ? "#fff" : "#372EE5"} />}
                                    disabled={buttonDisabled}
                                    size={"Medium"}
                                    onClick={OnDeleteClick}
                                    text={"Unarchive"}
                                >
                                    Unarchive
                                </Button>
                            </div>
                            <div className="flex gap-2 items-center">
                                {searchVisible && (
                                    <Input
                                        placeholder="Search..."
                                        value={searchInput}
                                        onChange={handleSearchChange}
                                        style={{ width: 200 }}
                                    />
                                )}

                                <Button
                                    icon={<SearchOutlined />}
                                    className="flex items-center border-none justify-center bg-[#F5F5F5] text-[#372EE5]"
                                    onClick={handleSearchToggle}
                                />
                                <Button
                                    icon={<ReloadOutlined />}
                                    onClick={() => { setReClick(!reClick) }}
                                    className="bg-[#F5F5F5] text-[#372EE5] flex items-center justify-center"
                                />
                            </div>
                        </div>
                        {loading ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-7 w-full my-5 gap-5">
                                {Array.from({ length: 8 }).map((_, index) => (
                                    <Skeleton.Input
                                        key={index}
                                        active
                                        style={{ width: 250, height: 110, borderRadius: 12 }}
                                    />
                                ))}
                            </div>
                        ) : filteredData.length === 0 ? (
                            <div className="flex flex-col items-center justify-center my-20">
                                <Empty description="No folders available" />
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-7 w-full my-5 gap-5">
                                {filteredData?.map((card, index) => (
                                    <ArchiveRoomDashboardCard
                                        key={index}
                                        cardTitle={card.name}
                                        color={card.color}
                                        onCheckboxChange={handleCardCheckboxChange}
                                        id={card._id}
                                        hasPermission={false}
                                        onClose={() => {setReClick(!reClick)}}
                                        fileCount={card.fileCount}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                }
                <ArchiveFileDataRoom/>
            </div>
        </div >
    );
}
