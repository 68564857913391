import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Tag,
    Table,
    ConfigProvider,
    Popover,
    Input,
    Empty,
} from "antd";
import "./index.css";
import DataRoom from "../localization/data_room/localization_en_data_room.json";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature";
import FileIC from "../assets/TableIcon/fileIc.png";
import OwnerIC from "../assets/TableIcon/owner.png";
import ReviewTick from "../assets/TableIcon/reviewTick.png";
import CommentIc from "../assets/TableIcon/commentIc.png";
import TagIc from "../assets/TableIcon/tags.png";
import LinkIc from "../assets/TableIcon/LinkIc.png";
import CirclePlus from "../assets/TableIcon/circlePlusIc.png";
import ModalSection from "../DesignSystem/Cards/ActionMenuOptionModal/ModalSection";
import ActionMenuCard from "../DesignSystem/Cards/ActionMenuCard";
import { generateSignedUrlForDownload, listDirectoryApi } from "../features/dataRoomSlice"
import {
    PlusOutlined,
    SearchOutlined,
    DownloadOutlined,
    DeleteOutlined,
    ReloadOutlined,
    LeftOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom"
import { D_ToggleCommentSection, D_UpdateChatRowOrCell } from "../components/Chat/chatSlice";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

export default function AdminDataRoomFile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { directoryName } = useParams();
    console.log(directoryName)
    const linkInfo = useSelector((state) => state.link);
    const activeItem = useSelector((state) => state.link.activeItem);
    const openKeys = useSelector((state) => state.link.openKeys);
    const username = useSelector((state) => state.user.value.username);
    const [featureData, setFeatureData] = useState({});
    const [flippedCards, setFlippedCards] = useState(
        new Array(featureData["cards"]?.length).fill(false)
    );
    const status = useSelector((state) => state.link.status);
    const progress = useSelector((state) => state.link.progress);
    const onboarding = useSelector((state) => state.onboarding.value.Company);
    const [animateOnMount, setAnimateOnMount] = useState(false);
    const [checkedCards, setCheckedCards] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selectedCardDetails, setSelectedCardDetails] = useState(null);
    const [checkedCardDetails, setCheckedCardDetails] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [dataRoomFile, setDataRoomFile] = useState([]);
    const [modalTitle, setModalTitle] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const user = useSelector((state) => state.user);

    const [deleteModalTitle, setDeleteModalTitle] = useState(null);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [documentList, setDocumentList] = useState("");
    const [reClick, setReClick] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState(dataRoomFile);
    const [searchVisible, setSearchVisible] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        // Filter the data based on the search input
        setFilteredData(
            dataRoomFile.filter((item) =>
                item.name.toLowerCase().includes(searchInput.toLowerCase())
            )
        );
    }, [searchInput, dataRoomFile]);

    const handleSearchToggle = () => {
        console.log("Toogle")
        setSearchVisible(!searchVisible);
    };

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setButtonDisabled(!newSelectedRowKeys.length);
        setSelectedRowKeys(newSelectedRowKeys);
        console.log(newSelectedRowKeys)
    };

    const showModalTag = (tags, id) => {
        setModalTitle("Tags");
        setIsModalVisible(true);
        setDocumentList({ tags, id })
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const handleAddCommentSection = (id) => {
        dispatch(D_UpdateChatRowOrCell({ _id: id, moduleType: "data-room" }))
        dispatch(D_ToggleCommentSection(true));
    }


    const columns = [
        {
            title: (
                <div className="flex items-center py-3 ml-2 bg-white">
                    <span className=" font-normal text-[#757575]">File ID</span>
                </div>
            ),
            dataIndex: "fileId",
            key: "fileId",
            render: (_, record) => {
                return (
                    <div className="py-2">
                        <h1 className="text-[#111827] font-medium text- pl-2">
                            {record.fileId}
                        </h1>
                    </div>
                );
            },
            width: 70,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img src={FileIC} alt="file-ic" className=" w-3 h-3.5 border mr-2" /> */}
                    <InsertDriveFileOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">File Name</h1>
                </div>
            ),
            dataIndex: "fileName",
            key: "fileName",
            render: (_, record) => {
                return (
                    <div className="flex">
                        <h1 className=" text-[#111827] font-medium font-sans truncate-to-1-lines">
                            {record.name}
                        </h1>
                    </div>
                );
            },
            width: 240,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img
                        src={OwnerIC}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    /> */}
                    <PersonOutlineOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    
                    <h1 className="m-0  font-normal text-[#757575]">Owner</h1>
                </div>
            ),
            dataIndex: "ownerName",
            key: "ownerName",
            render: (_, record) => {
                return (
                    <div className="flex items-center py-1 relative">
                        <div className="absolute left-[-25px] top-[-0.5px]">
                            {record.lockStatus ? (
                                <div className="scale-125">🔒</div>
                            ) : (
                                null
                            )}
                        </div>
                        <div className=" flex items-center justify-center mr-2 w-5 h-5 rounded-full bg-orange-200">
                            <h1 className=" text-orange-500 m-0 text-[10px]">
                                {(user.value.username)[0]}
                            </h1>
                        </div>
                        <div className=" flex  items-center">
                            <h1 className=" text-[#111827] font-sans text-sm m-0 font-medium tracking-wide">
                                {user.value.username}
                            </h1>
                        </div>
                    </div>
                );
            },
            width: 100,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img
                        src={ReviewTick}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    /> */}
                    <CheckCircleOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">Review Progress</h1>
                </div>
            ),
            dataIndex: "status",
            key: "status",
            render: (_, record) => {

                const getColor = (status) => {
                    let color;
                    let text;
                    switch (status) {
                        case "In Progress":
                            color = "orange";
                            text = "In Progress";
                            break;
                        case "Completed":
                            color = "green";
                            text = "Completed";
                            break;
                        case "Not Assigned":
                            color = "red";
                            text = "Not Assigned";
                            break;
                        default:
                            color = "blue";
                            text = "Hover To View";
                    }

                    return { color: color, text: text }

                }

                const progressData = record.sharingDetails || [];
                const content = (
                    <div className={`p-4 border rounded-lg`}>
                        {progressData.length > 0 ? (
                            progressData.map((value) => (
                                <div key={value._id} className="mb-1 flex justify-center flex-col">
                                    <div className="w-full flex items-center justify-center py-2">{value.sharedUserId.username}</div>
                                    <div
                                        className={`flex items-center justify-center border border-[${getColor(value.reviewStatus).color}] bg-opacity-50 rounded-full py-1.5 bg-${getColor(value.reviewStatus).color}-200 w-full`}
                                    >
                                        <div
                                            className={`bg-opacity-50 mr-2 w-1 h-1 rounded-full`}
                                            style={{ backgroundColor: getColor(value.reviewStatus).color }}
                                        />
                                        <h1 className={`text-${getColor(value.reviewStatus).color}-500 m-0 text-[11px] font-bold`}>{value.reviewStatus}</h1>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No progress data available.</p>
                        )}
                    </div>
                )
                console.log(content)
                return (
                    <Popover content={content} title="User Progress">
                        <div className="flex items-center py-0.5">
                            <div
                                className={`flex items-center justify-center border border-blue-500 bg-opacity-50 rounded-full py-1.5 w-[90%]`}
                            >
                                <div
                                    className={`bg-blue-500 bg-opacity-50 mr-2 w-1 h-1 rounded-full`}
                                />
                                <h1 className={`text-blue-500 m-0 text-[11px]`}>{"Hover"}</h1>
                            </div>
                        </div>
                    </Popover>
                );
            },
            width: 150,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white pl-4">
                    {/* <img
                        src={CommentIc}
                        alt="file-ic"
                        className=" w-3 object-contain h-3 border mr-2"
                    /> */}
                    <ChatBubbleOutlineOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">Comment</h1>
                </div>
            ),
            key: "comments",
            render: (_, record) => {
                return (
                    <button className="flex pl-4 py-0.5" onClick={() => { handleAddCommentSection(record._id) }}>
                        <div className=" text-[#372EE5] font-semibold cursor-pointer ">
                            Add Comment
                        </div>
                    </button>
                );
            },
            width: 150,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img
                        src={TagIc}
                        alt="file-ic"
                        className=" w-3 object-contain h-4 border mr-2"
                    /> */}
                    <SellOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">Tags</h1>
                </div>
            ),
            key: "tags",
            dataIndex: "tags",
            render: (_, records) => (
                <div className="py-0.5">
                    {records.tags ? (
                        <>
                        {records.tags.split(',').map((tag) => (
                            <Tag color="blue" className="rounded-full" key={tag.trim()}>
                                {tag.trim()}
                            </Tag>
                        ))}
                        <Tag color="cyan" className="rounded-full font-bold">
                            <div onClick={() => { showModalTag(records.tags, records._id) }}>+</div>
                        </Tag>
                        </>
                    ) : (
                        <Tag color="cyan" className="rounded-full font-bold">
                            <div onClick={() => { showModalTag(records.tags, records._id) }}>+</div>
                        </Tag>
                    )}
                </div>
            ),
            width: 250,
        },
        {
            title: (
                <div className="flex items-center justify-start py-3 bg-white">
                    {/* <img
                        src={LinkIc}
                        alt="file-ic"
                        className=" w-4 object-contain h-3 border mr-2"
                    /> */}
                    <AddLinkOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                    <h1 className="m-0  font-normal text-[#757575]">Linked Docs</h1>
                </div>
            ),
            dataIndex: "linkedDocs",
            key: "linkedDocs",
            render: (linkedDocs) => (
                <div className="flex py-0.5">
                    <span className=" text-[#424242] font-medium">{linkedDocs ? linkedDocs : "0 Linked Docs"}</span>
                </div>
            ),
            width: 150,
        },
        {
            title: (
                <div className="flex items-center justify-center py-3 bg-white">
                    {/* <img
            src={LinkIc}
            alt="file-ic"
            className=" w-3 object-contain h-3 border mr-2"
          />
          <h1 className="m-0  font-normal text-[#757575]">Respond</h1> */}
                    {/* <img
                        src={CirclePlus}
                        alt="file-ic"
                        className="w-3 object-contain h-5 border ml-5"
                    /> */}
                    <AddCircleOutlineOutlinedIcon fontSize="small" className="text-gray-500 mr-2" />
                </div>
            ),
            key: "actions",
            render: (_, record) => (
                <div className="py-1.5 w-full flex justify-center bg-white">
                    <ActionMenuCard document={record} handleClose={() => { setReClick(!reClick) }} />
                </div>
            ),
            width: 40,
            "fixed": "right"
        },
    ];
    const handleCardCheckboxChange = (isChecked, cardDetails) => {
        if (isChecked) {
            const updatedCheckedCards = [...checkedCardDetails, cardDetails];
            console.log("Updated Checked Cards:", updatedCheckedCards);
            setCheckedCardDetails(updatedCheckedCards);
        } else {
            const filteredCards = checkedCardDetails.filter(
                (card) => card.id !== cardDetails.id
            );
            console.log("Updated Checked Cards:", filteredCards);
            setCheckedCardDetails(filteredCards);
        }
    };

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true)
            try {
                const body = {
                    directory: directoryName
                }
                const response = await dispatch(listDirectoryApi(body));
                const listOfSharing = response?.payload.listOfSharing || [];
                const extractedData = response.payload?.listOfFiles.map((obj, index) => {
                    return {
                        _id: obj._id,
                        organisationId: obj.organisationId,
                        userId: obj.userId,
                        name: obj.name,
                        path: obj.path,
                        type: obj.file,
                        description: obj.description,
                        archiveStatus: obj.archiveStatus,
                        tags: obj.tags,
                        color: obj.color,
                        size: obj.size,
                        lockStatus: obj.lockStatus,
                        createdAt: obj.createdAt,
                        updatedAt: obj.updatedAt,
                        sharingDetails: listOfSharing[index],
                        fileId: index + 1,
                    }
                })
                setDataRoomFile(extractedData)
                console.log(extractedData)
            } catch (error) {
                console.error("Error : ", error)
            } finally {
                setIsLoading(false)
                if (isInitialLoad) {
                    setIsInitialLoad(false)
                }
            }
        }

        getData()
    }, [dispatch, reClick])

    useEffect(() => {
        const anyCardChecked = checkedCardDetails.length === 0;
        setButtonDisabled(anyCardChecked);

        setSelectedCardDetails(anyCardChecked ? null : checkedCardDetails);
        console.log("checkedCardDetails", checkedCardDetails);
    }, [checkedCardDetails]);


    //   Add File Modal
    const handleButtonClick = () => {
        setModalTitle("Add File"); // Change the index as needed
        console.log(directoryName)
        setDocumentList(directoryName)
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setModalTitle(null);
    };
    //   Delete Modal
    const handleDeleteButton = () => {
        setModalTitle("Delete File"); // Change the index as needed
        setIsModalVisible(true);
        setDocumentList(selectedRowKeys)
    };

    const closeDeleteModal = () => {
        setIsModalVisible(false);
        setIsDeleteModalVisible(null);
    };

    const onDownloadClick = async () => {
        setLoading(true);
        const filteredObjects = dataRoomFile.filter(obj => selectedRowKeys.includes(obj._id));

        for (const documentMain of filteredObjects) {
            const body = {
                directory: directoryName,
                filename: documentMain.name
            };

            const result = await dispatch(generateSignedUrlForDownload(body));
            if (result && result.payload && result.payload.url) {
                console.log("entered")
                const downloadUrl = result.payload.url;
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.target = '_blank';
                link.download = document.name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        setLoading(false)
    };

    const noDataContent = (
        <div className="py-10">
            <Empty description={!searchVisible ? `To begin, please add a file to your data room to keep your information organized.` : `No results for ${searchInput}. Please try refining your search and go again.`} />
        </div>
    )

    const SkeletonLoader = ({ avatar = false, active = true, loading = true }) => {
        return (
            <div className="bg-neutral-200 animate-pulse w-full h-10 rounded-lg">
            </div>
        );
    };

    return (
        <div className="pt-[6rem] min-h-[100vh]">
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    isOpen={true}
                    closeHandler={() => {
                        setIsModalVisible(false);
                        setModalTitle("");
                        setDocumentList("")
                        setReClick(!reClick)
                    }}
                    documentList={documentList}
                    secondaryCloseHandler={() => {
                        setIsModalVisible(true);
                        setModalTitle("");
                        setDocumentList("")
                    }}
                />
            )}

            {isLoading && !isInitialLoad && (
                <div className="absolute bottom-0 left-0 w-full flex items-center justify-center p-4 bg-white">
                    <div className="flex items-center absolute right-10 bottom-4 shadow-lg p-4 rounded-lg">
                        <div className="w-6 h-6 border-4 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
                        <span className="ml-2">Refreshing data</span>
                    </div>
                </div>
            )}
            <div className={`w-[100%] ${animateOnMount && "fade-in-out"}`}>
                <div className="mx-auto">
                    <Button
                        type="text"
                        icon={<LeftOutlined />}
                        onClick={() => navigate(-1)}
                        className="mb-4 flex items-center justify-center"
                    >
                        Back
                    </Button>
                    <DescribeFeature
                        text={DataRoom.data_room.dasboard.title}
                        description={DataRoom.data_room.dasboard.description}
                    />
                    <div className="flex justify-between items-center">
                        <p className="font-DMSans text-[#111827] text-[18px] mt-6">
                            Company Information Files
                        </p>
                        <div className="flex gap-2 items-center">
                            {searchVisible && (
                                <Input
                                    placeholder="Search..."
                                    value={searchInput}
                                    onChange={handleSearchChange}
                                    style={{ width: 200 }}
                                />
                            )}
                            <Button
                                icon={<SearchOutlined />}
                                className="flex items-center justify-center bg-[#EBF1FF] text-[#372EE5]"
                                onClick={handleSearchToggle}
                            />
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={handleButtonClick}
                                className="bg-[#372EE5] font-semibold"
                            >
                                Add File
                            </Button>

                        </div>
                    </div>
                    <div className="w-full h-fit justify-between flex">
                        <div className="flex gap-5 mt-4">
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                disabled={buttonDisabled}
                                className="bg-[#372EE5]"
                                onClick={onDownloadClick}
                                loading={loading}
                            >
                                Download
                            </Button>
                            <Button
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                disabled={buttonDisabled}
                                onClick={handleDeleteButton}
                            >
                                Delete
                            </Button>
                        </div>
                        <div>
                            <Button
                                icon={<ReloadOutlined />}
                                onClick={() => { setReClick(!reClick) }} // Call handleReloadClick on click
                                className="bg-[#F5F5F5] text-[#372EE5] flex items-center justify-center"
                            />
                        </div>
                        {isDeleteModalVisible && (
                            <ModalSection
                                title={deleteModalTitle}
                                closeHandler={closeDeleteModal}
                                documentList={rowSelection?.selectedRowKeys}
                            />
                        )}
                    </div>
                    <div className="mt-4 overflow-hidden">
                        {filteredData.length === 0 && !isInitialLoad ? (
                            noDataContent
                        ) : isInitialLoad ? (
                            <div className="w-full flex flex-col gap-3">
                                <SkeletonLoader />
                                <SkeletonLoader />
                                <SkeletonLoader />
                                <SkeletonLoader />
                            </div>
                        ) : (
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Table: {
                                            headerBg: "#FFFFFF",
                                        },
                                    },
                                }}
                            >
                                <Table
                                    columns={columns}
                                    dataSource={filteredData}
                                    rowSelection={rowSelection}
                                    scroll={{ x: 1500 }}
                                    rowKey={(record) => record._id}
                                    className="border border-[#9E9E9E] rounded-2xl overflow-hidden"
                                    rowClassName={() =>
                                        "bg-white border border-[#9E9E9E] px-3 mb-2"
                                    }
                                    pagination={false}
                                    rowHoverable={false}
                                />
                            </ConfigProvider>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
