import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
import { useState, useEffect } from "react";
import { updateTagsApi } from "../../../features/dataRoomSlice";
import { useDispatch } from "react-redux";
import { message, Modal, notification } from 'antd';
import TextInput from "../../Forms/InputVarients/TextInput";
import CloseButton from '../../SVG/CloseButtonSVG';
import ModalSection from './ModalSection';

const MAX_TAGS = 5;

const AddTagsModal = ({ isOpen, onClose, documentList }) => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState("");
    const [tags, setTags] = useState([]);
    const [modalOpen, setModalOpen] = useState(isOpen);
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (documentList.tags) {
            const initialTags = documentList.tags.split(',').map(tag => tag.trim());
            setTags(initialTags);
        }
    }, [documentList]);

    const handleOk = () => {
        setModalOpen(false);
        onClose();
    };

    const handleCancel = () => {
        setModalOpen(false);
        onClose();
    };

    const handleInputChange = (e) => {
        const { value } = e.target;
        setInputValue(value);

        if (value.endsWith(' ')) {
            addTag(value.trim());
            setInputValue('');
        }
    };

    const addTag = (tag) => {
        if (tag && !tags.includes(tag) && tags.length < MAX_TAGS) {
            setTags([...tags, tag]);
        }
    };

    const removeTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
    };

    const handleSubmit = async () => {
        if(tags.length === 0 ) {
            // notification.error({
            //     message: "No Tags Found",
            //     description: "Add Tags to update the tags for the speceific dowcument"
            // })
            message.error("No Tags Found, Add Tags to update the tags for the speceific dowcument")
            return;
        }
        setLoading(true);
        const tagsString = tags.join(', ');
        const body = {
            documentId: documentList.id,
            tags: tagsString,
        };
        await dispatch(updateTagsApi(body)).then((response) => {
            setLoading(false);
            if(response) {
                // notification.info({
                //     message: "Success",
                //     description: "Tag has been added Successfully"
                // })
                message.success("Tag has been added Successfully")
            }
            handleCancel();
        });
    };

    // Handle single tag input when user blurs or submits
    const handleBlur = () => {
        if (inputValue.trim() && !tags.includes(inputValue.trim()) && tags.length < MAX_TAGS) {
            addTag(inputValue.trim());
            setInputValue('');
        }
    };

    return (
        <>
            {modalVisible ? (
                <ModalSection title="Folder Info" closeHandler={() => { setModalOpen(false); handleCancel(); }} />
            ) : null}
            <Modal
                title={<span style={{ fontSize: '20px' }}>Add Tags</span>}
                centered
                open={modalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={handleCancel}>
                    <CloseButton />
                </button>

                <div className="flex flex-col">
                    <h1 className="text-[#111827] text-[16px]">Tags</h1>

                    <div className="flex flex-wrap items-center border rounded p-2">
                        {tags.map((tag, index) => (
                            <div key={index} className="flex items-center bg-gray-200 rounded p-1 m-1">
                                <span>{tag}</span>
                                <button onClick={() => removeTag(index)} className="ml-1">x</button>
                            </div>
                        ))}
                        <input
                            className="flex-grow p-1 outline-none"
                            placeholder="Type and press space to add tags"
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleBlur} // Handle single tag input when user blurs
                        />
                    </div>
                </div>

                <div className="flex justify-between mt-3">
                    <SecondaryButton text={"Cancel"} onClick={handleCancel} size="Medium" isDisabled={loading}/>
                    <PrimaryButton text={"Add Tags"} onClick={handleSubmit} size="Medium" isLoading={loading} isDisabled={loading}/>
                </div>
            </Modal>
        </>
    );
};

export default AddTagsModal;

