import React, { useState, useEffect } from 'react';
import { Modal, notification, Spin, AutoComplete } from 'antd';
import closeSvg from "../../../assets/common/close.svg";
import demoUser from "../../../assets/common/demoUser.svg";
import SecondaryButton from '../../Buttons/SecondaryButton';
import PrimaryButton from '../../Buttons/PrimaryButton';
import ModalSection from "./ModalSection";
import { useDispatch } from 'react-redux';
import { fetchAllInvestorApi, shareDocumentsApi } from '../../../features/dataRoomSlice';

const ShareModal = ({ isOpen, onClose, documentList }) => {
    const [usersSelected, setUsersSelected] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [investorList, setInvestorList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mainLoading, setMainLoading] = useState(false);
    const [searchOptions, setSearchOptions] = useState([]);
    const dispatch = useDispatch();
    const document = documentList[0];

    useEffect(() => {
        const fetchInvestors = async () => {
            setLoading(true);
            try {
                const result = await dispatch(fetchAllInvestorApi());
                if (result.payload && result.payload.users) {
                    const sharedEmails = new Set(document.sharingDetails.map(detail => detail.sharedUserId.email));
                    const filteredInvestors = result.payload.users.filter(user => !sharedEmails.has(user.email));
                    const unfilteredInvestors = result.payload.users.filter(user => sharedEmails.has(user.email));
                    setInvestorList(filteredInvestors);
                    setUsersSelected(unfilteredInvestors);
                }
            } catch (error) {
                console.error('Error fetching investors:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInvestors();
    }, [dispatch, document]);

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    const toggleUserSelection = (user) => {
        setUsersSelected(prevSelected => {
            const isAlreadySelected = prevSelected.some(selectedUser => selectedUser._id === user._id);
            if (isAlreadySelected) {
                return prevSelected.filter(selectedUser => selectedUser._id !== user._id);
            } else {
                return [...prevSelected, user];
            }
        });
    };

    const handleOk = () => {
        setModalOpen(false);
        onClose();
    };

    const handleCancel = () => {
        setModalOpen(false);
        onClose();
    };

    const handleShare = async () => {
        setMainLoading(true);
        if (usersSelected.length === 0) {
            notification.info({
                message: "Error",
                description: "No Investor Selected"
            })
            return
        }
        try {
            const userIdList = usersSelected.map(user => user._id);
            const body = {
                documentId: document.id,
                userIdList: userIdList.filter((x) => x !== null)
            };
            const response = await dispatch(shareDocumentsApi(body));
            if (response?.meta.requestStatus === "fulfilled") {
                notification.info({
                    message: "Success",
                    description: "Selected users have been granted access"
                });
            } else {
                notification.error({
                    message: "Error",
                    description: "Failed to grant access to the users. Please try again."
                });
            }
            handleOk();
        } catch (error) {
            console.error('Error sharing documents:', error);
        } finally {
            setMainLoading(false);
            setModalTitle("Info");
            setModalVisible(true);
        }
    };

    const handleSubmit = async () => {
        await handleShare();
        setModalOpen(false);
        setModalTitle("Access Info");
        setModalVisible(true);
    };

    const handleAddInvestor = () => {
        setModalOpen(false);
        setModalTitle("Edit Investor Details");
        setModalVisible(true);
    };

    const handleSearch = (value) => {
        if (value) {
            const options = investorList
                .filter(user => user.email.toLowerCase().includes(value.toLowerCase()))
                .map(user => ({ value: user.email, label: user.email, user }));
            setSearchOptions(options);
        } else {
            setSearchOptions([]);
        }
    };

    const handleSelect = (value) => {
        const selectedUser = searchOptions.find(option => option.value === value)?.user;
        if (selectedUser) {
            toggleUserSelection(selectedUser);
            setSearchOptions([]);
        }
    };

    return (
        <>
            {modalVisible && (
                <ModalSection
                    title={modalTitle}
                    closeHandler={() => {
                        setModalVisible(false);
                        handleCancel();
                    }}
                    text={"Duccess! The selected users now have access to the folder."}
                />
            )}
            <Modal
                title={<span style={{ fontSize: '18px' }}>Share with multiple recipients</span>}
                centered
                visible={modalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <button
                    className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all'
                    onClick={handleCancel}
                >
                    <img src={closeSvg} alt="Close" />
                </button>
                <div className="px-2 flex flex-col gap-3">
                    <div className="flex flex-col gap-4 mt-5">
                        {/* User Selection */}
                        <div className="w-full flex flex-col gap-4">
                            <AutoComplete
                                style={{ width: '100%' }}
                                options={searchOptions}
                                onSearch={handleSearch}
                                onSelect={handleSelect}
                                placeholder="Search users to share with"
                                loading={loading}
                                allowClear
                            />
                        </div>
                        {/* Selected Users List */}
                        <div className="w-full flex flex-col gap-4">
                            <div className="text-lg font-medium">Sharing With</div>
                            <div className="max-h-[200px] overflow-y-auto hide-scrollbar">
                                {usersSelected.map(user => (
                                    <div key={user._id} className="flex w-full p-2 justify-between items-center">
                                        <div className="flex items-center justify-center flex-row gap-2">
                                            <img src={demoUser} alt="user" className="border mr-1" />
                                            <h1 className="text-[#424242] text-[16px] font-medium my-auto">{user.email}</h1>
                                        </div>
                                        <button
                                            className="text-black"
                                            onClick={() => toggleUserSelection(user)}
                                        >
                                            ×
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-row justify-between mt-3">
                        <SecondaryButton
                            text={"Add Investor"}
                            onClick={handleAddInvestor}
                            size="Medium"
                            isDisabled={mainLoading}
                        />
                        <PrimaryButton
                            text={usersSelected.length !== 0 ? "Renew Access" : "Grant Access"}
                            size="Medium"
                            onClick={handleSubmit}
                            isDisabled={mainLoading || usersSelected.length === 0}
                            isLoading={mainLoading}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ShareModal;