import React, { useEffect, useState } from "react";
import { Button, Dropdown, Menu, Space } from "antd";
import AdminDataRoom from "../../localization/data_room/localization_en_admin_data_room.json";
import MoveArrow from "../SVG/MoveArrowSVG";
import AcessPanel from "../SVG/AcessPanelSVG";
import Info from "../SVG/InfoSVG";
import Create from "../SVG/CreateSVG.jsx";
import DeleteOutlineSVG from "../SVG/DeleteOutlineSVG.jsx";
import DownloadOutlineSVG from "../SVG/DownloadOutlineSVG.jsx";
import ColorCodeSVG from "../SVG/ColorCodeSVG.jsx";
import DiscriptionSVG from "../SVG/DiscriptionSVG.jsx";
import ArchiveOutline from "../SVG/ArchiveOutlineSVG.jsx";
import CopyOutline from "../SVG/CopyOutlineSVG.jsx";
import grayDots from "../../assets/common/grayDots.svg";
import ModalSection from "./ActionMenuOptionModal/ModalSection.jsx";
import { copyFileApi, updateArchiveStatusApi, updateColor } from "../../features/dataRoomSlice.jsx";
import { useDispatch } from "react-redux";

const ActionMenuFolderCard = ({ documentId, handleClose, handleDashColorChange, directoryName }) => {
    const [modalTitle, setModalTitle] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [documentList, setDocumentList] = useState(documentId);
    const [renameFolderInitialValue] = useState(directoryName);
    const dispatch = useDispatch()
    const handleMenuClick = (e) => {
        console.log("click", e);
        setModalTitle(AdminDataRoom.admin_action_menu.actionMenu[e.key]);
        setIsModalVisible(true);
        if (e.key === "6") {
            setDocumentList([documentId])
        } else if (e.key === "5") {
            setDocumentList({_id: documentId, name: ".txt", context: "folder"})
        } else {
            setDocumentList(documentId);
        }
    };

    const handleColorChange = async (color) => {
        handleDashColorChange(true)
        const body = {
            documentId: documentId,
            color: color
        }
        await dispatch(updateColor(body)).then(() => { console.log("Hello"); handleClose(); handleDashColorChange(false, color) })
        setModalTitle("Info");
        setIsModalVisible(true)
    };

    const closeModalHandler = () => {
        setIsModalVisible(false);
        setModalTitle(null);
        handleClose();
    };

    const handleArchiveClick = async () => {
        const body = {
            documentId: documentId,
            archiveStatus: true
        }

        await dispatch(updateArchiveStatusApi(body));
        handleClose();
    }

    const handleMakeCopy = async () => {
        const body = {
            documentId: documentId
        }

        await dispatch(copyFileApi(body))
    }

    const handleRenameClick = async () => {
        const newDocumentList = {
            _id: documentId,
            name: "Renamed the file"
        }

        setDocumentList(newDocumentList)
        setModalTitle(AdminDataRoom.admin_action_menu.actionMenu['5'])
        setIsModalVisible(true)
    }

    const colorItems = [
        {
            key: "12-1",
            label: (
                <Space onClick={() => handleColorChange("#C688FA")}>
                    <span
                        style={{
                            backgroundColor: "#C68BFA",
                            borderRadius: "50%",
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                        }}
                    ></span>
                </Space>
            ),
        },
        {
            key: "#75A6f1",
            label: (
                <Space onClick={() => handleColorChange('#75A6F1')}>
                    <span
                        style={{
                            backgroundColor: "#75A6F1",
                            borderRadius: "50%",
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                        }}
                    ></span>
                </Space>
            ),
        },
        {
            key: "12-3",
            label: (
                <Space onClick={() => handleColorChange('#F17D7D')}>
                    <span
                        style={{
                            backgroundColor: "#F17D7D",
                            borderRadius: "50%",
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                        }}
                    ></span>
                </Space>
            ),
        },
        {
            key: "12-4",
            label: (
                <Space onClick={() => handleColorChange('#81F1A6')}>
                    <span
                        style={{
                            backgroundColor: "#81F1A6",
                            borderRadius: "50%",
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                        }}
                    ></span>
                </Space>
            ),
        },
        {
            key: "12-5",
            label: (
                <Space onClick={() => handleColorChange('#F1E2A6')}>
                    <span
                        style={{
                            backgroundColor: "#F1E2A6",
                            borderRadius: "50%",
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                        }}
                    ></span>
                </Space>
            ),
        },
        {
            key: "12-6",
            label: (
                <Space onClick={() => handleColorChange('#F1F1F1')}>
                    <span
                        style={{
                            backgroundColor: "#F1F1F1",
                            borderRadius: "50%",
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                        }}
                    ></span>
                </Space>
            ),
        },
    ];

    const menuItems = [
        {
            label: (
                <Space className="py-2">
                    <MoveArrow />
                    {AdminDataRoom.admin_action_menu.actionMenu[1]}
                </Space>
            ),
            key: "1",
        },
        {
            label: (
                <Space className="py-2">
                    <AcessPanel />
                    {AdminDataRoom.admin_action_menu.actionMenu[2]}
                </Space>
            ),
            key: "2",
        },
        {
            label: (
                <Space className="py-2">
                    <Info />
                    {AdminDataRoom.admin_action_menu.actionMenu[4]}
                </Space>
            ),
            key: "24",
        },
        // {
        //     label: (
        //         <Space className="py-2" onClick={handleRenameClick}>
        //             <Create />
        //             {AdminDataRoom.admin_action_menu.actionMenu[5]}
        //         </Space>
        //     ),
        //     key: "5",
        // },
        // {
        //   label: (
        //     <Space className="py-2" onClick={() => {}}>
        //       <DownloadOutlineSVG />
        //       {AdminDataRoom.admin_action_menu.actionMenu[10]}
        //     </Space>
        //   ),
        //   key: "10",
        // },
        {
            label: (
                <Space className="py-2">
                    <ColorCodeSVG />
                    {AdminDataRoom.admin_action_menu.actionMenu[13]}
                </Space>
            ),
            key: "13",
            children: colorItems,
        },
        {
            label: (
                <Space className="py-2">
                    <ColorCodeSVG />
                    More
                </Space>
            ),
            key: "14",
            children: [
                // {
                //   key: "13-1",
                //   label: (
                //     <Space onClick={handleMakeCopy}>
                //       <CopyOutline />
                //       {"Make a Copy"}
                //     </Space>
                //   ),
                // },
                {
                    key: "18",
                    label: (
                        <Space>
                            <DiscriptionSVG />
                            {AdminDataRoom.admin_action_menu.actionMenu[18]}
                        </Space>
                    ),
                },
                {
                    key: "30",
                    label: (
                        <Space onClick={handleArchiveClick}>
                            <ArchiveOutline />
                            {AdminDataRoom.admin_action_menu.actionMenu[8]}
                        </Space>
                    ),
                },
                {
                    key: "6",
                    label: (
                        <Space>
                            <DeleteOutlineSVG />
                            {AdminDataRoom.admin_action_menu.actionMenu[6]}
                        </Space>
                    ),
                },
            ],
        },
    ];

    const menu = <Menu onClick={handleMenuClick} items={menuItems} />;

    return (
        <Space wrap>
            <Dropdown overlay={menu} trigger={["click"]}>
                <Space className="cursor-pointer opacity-75 hover:opacity-100">
                    <img src={grayDots} />
                </Space>
            </Dropdown>
            {isModalVisible && (
                <ModalSection
                    title={modalTitle}
                    initialValue={renameFolderInitialValue}
                    closeHandler={closeModalHandler}
                    documentList={documentList} // Pass any required document list or other props
                    text={"Folder Color Has been changed"}
                />
            )}
        </Space>
    );
};

export default ActionMenuFolderCard;
