import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, Input, Button, Checkbox, Spin, notification } from "antd";
import SecondaryButton from "../../Buttons/SecondaryButton";
import CloseButton from "../../SVG/CloseButtonSVG";
import { documentuserShareList, linkDocumentApi, treeApi } from "../../../features/dataRoomSlice";
import PrimaryButton from "../../Buttons/PrimaryButton";

const LinkDocumentModal = ({ isOpen, onClose, documentList }) => {
    const [checkedItems, setCheckedItems] = useState({});
    const [fileLink, setFileLink] = useState("");
    const [modalVisible, setModalVisible] = useState(isOpen);
    const [folders, setFolders] = useState({});
    const [loading, setLoading] = useState(false);
    const [ids, setIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();

    console.log("This is the link document", documentList)

    useEffect(() => {
        setModalVisible(isOpen);
        const fetchData = async () => {
            if (isOpen) {
                setLoading(true);
                const response = await dispatch(treeApi()).unwrap();
                processDocumentList(response.documentList);
            }
        };
        fetchData();
    }, [isOpen, dispatch]);

    const processDocumentList = (list) => {
        const folderStructure = {};

        list.forEach((doc) => {
            if (doc.type === "file") {
                const folderName = doc.path.split('/').slice(-2, -1)[0];
                if (!folderStructure[folderName]) {
                    folderStructure[folderName] = [];
                }
                folderStructure[folderName].push({
                    id: doc._id,
                    name: doc.name,
                    type: doc.type,
                    path: doc.path,
                });
            }
        });

        setFolders(folderStructure);
        setLoading(false);
    };

    const handleOk = () => {
        setModalVisible(false);
        onClose();
    };

    const handleCancel = () => {
        setModalVisible(false);
        onClose();
    };

    const handleCheckboxChange = (folder, file, isChecked, id) => {
        setCheckedItems((prevState) => ({
            ...prevState,
            [folder]: {
                ...prevState[folder],
                [file]: isChecked,
            },
        }));

        if (isChecked) {
            setIds((prevState) => [...prevState, id]);
        } else {
            setIds((prevState) => prevState.filter((existingId) => existingId !== id));
        }
    };

    const handleAddLink = async () => {
        console.log(ids)
        setIsLoading(true)
        const body = {
            documentId: documentList?._id,
            linkDocumentIdList: ids
        }
        await dispatch(linkDocumentApi(body)).then((response) => {
            if(response.meta.requestStatus === "fulfilled") {
                setIsLoading(false);
                notification.info({
                    message: "Success",
                    description: "File Linking Successfull"
                })
                handleCancel()
            } else {
                setIsLoading(false);
                notification.error({
                    message: "Error",
                    description: "File lik can't be added."
                })
            }
        })
    };

    return (
        <Modal
            title={`Select the files you wish to link to ${documentList.name}`}
            centered
            visible={modalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="link" type="primary" onClick={handleOk}>
                    Link
                </Button>,
            ]}
        >
            <button
                className="absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all"
                onClick={handleCancel}
            >
                <CloseButton />
            </button>

            <div style={{ maxHeight: "400px", overflow: "auto" }}>
                {loading ? (
                    <div className="flex justify-center items-center">
                        <Spin size="large" />
                    </div>
                ) : (
                    Object.entries(folders).map(([folder, files]) => (
                        <div key={folder} className="mb-4">
                            <div className="font-medium text-gray-900 mb-2 cursor-pointer">
                                {folder}
                            </div>
                            {files.map((file) => (
                                <div
                                    key={file.name}
                                    className="flex items-center justify-between px-8 py-2 font-normal"
                                >
                                    {file.name}
                                    <Checkbox
                                        checked={checkedItems[folder]?.[file.name] || false}
                                        onChange={(e) => handleCheckboxChange(folder, file.name, e.target.checked, file.id)}
                                    />
                                </div>
                            ))}
                        </div>
                    ))
                )}
            </div>
            <div className="flex justify-between">
                <SecondaryButton onClick={handleCancel} size="Medium" text={"Cancel"} isDisabled={isLoading}/>
                <PrimaryButton onClick={handleAddLink} size="Medium" text={"Add"} isLoading={isLoading} isDisabled={isLoading}/>
            </div>

        </Modal>
    );
};

export default LinkDocumentModal;

