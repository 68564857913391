import React, { useEffect, useState } from 'react';
import { Drawer, Input, Button, Upload, Avatar, Space, Card, message, Spin } from 'antd';
import { PlusOutlined, FileOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
import CloseButton from '../../SVG/CloseButtonSVG';
import { useDispatch } from 'react-redux';
import {
    questionAskReplyListApi,
    questionDeleteApi,
    questionEntryDeleteApi,
    questionReply,
    questionResolve,
    generateSignedUrl,
    createFileApi,
    questionAttachement
} from '../../../features/dataRoomSlice';

const FileAttachment = ({ name, size, type }) => (
    <div style={{
        border: '1px solid #d9d9d9',
        borderRadius: '8px',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
        backgroundColor: '#F5F5F5'
    }}>
        <FileOutlined style={{ fontSize: '24px', marginRight: '12px' }} />
        <div style={{ flexGrow: 1 }}>
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{name}</div>
            <div style={{ color: 'gray', fontSize: '12px' }}>{size} | {type}</div>
        </div>
        <Button type="link" style={{ color: '#1890ff' }}>Open</Button>
    </div>
);

const { TextArea } = Input;

const DiscussionThreadDrawer = ({ isOpen, onClose, documentList }) => {
    const [response, setResponse] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(isOpen);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null); // State for selected file
    const dispatch = useDispatch();

    const fetchMessages = async () => {
        setLoading(true);
        try {
            console.log(documentList, "documentList")
            const body = { addFolder: "", "conversationId": documentList["_id"] };
            const result = await dispatch(questionAskReplyListApi(body));
            if (result.payload && result.payload.getList) {
                setMessages(result.payload.getList);
            }
        } catch (error) {
            message.error('Failed to fetch data');
        } finally {
            setLoading(false);
        }
    };

    console.log(documentList, "==debug==")

    const handleCancel = () => {
        setDrawerOpen(false);
        onClose();
    };

    useEffect(() => {
        if (documentList && documentList._id && documentList?.documentId) {
            fetchMessages();
        }
    }, [dispatch, documentList]);

    const handleResponseChange = (e) => {
        setResponse(e.target.value);
    };

    const handleReply = async () => {
        setLoading(true);
        try {
            let fileUrl = null;
            console.log(file)

            if (file) {
                const sendFileName = async (fileName) => {
                    const body = {
                        directory: documentList?.directoryName,
                        filename: fileName,
                        uploadType: "attachement"
                    };
                    const response = await dispatch(generateSignedUrl(body));
                    return response;
                };

                const uploadFile = async (uploadUrl, file) => {
                    const readFileAsBinary = (file) => {
                        return new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                resolve(reader.result);
                            };
                            reader.onerror = reject;
                            reader.readAsArrayBuffer(file);
                        });
                    };

                    try {
                        const fileBinary = await readFileAsBinary(file);
                        const response = await fetch(uploadUrl, {
                            method: 'PUT',
                            body: fileBinary
                        });
                        return response.ok;
                    } catch (error) {
                        console.log("Error Uploading File, ", error);
                        return false;
                    }
                };

                const response = await sendFileName(file.name);
                const uploadUrl = response?.payload?.url;

                if (uploadUrl) {
                    const uploadFileResult = await uploadFile(uploadUrl, file);
                    if (uploadFileResult) {
                        const finalBody = {
                            directory: documentList?.directoryName,
                            file: file.name
                        };
                        const final = await dispatch(questionAttachement(finalBody));
                        if (final.error) {
                            message.error("Directory and File already exists.");
                            return;
                        } else {
                            fileUrl = file.name;
                            message.success("File successfully added to the current directory.");
                        }
                    } else {
                        message.error("Failed to add file to preferred directory. Please try again.");
                        return;
                    }
                } else {
                    message.error("Failed to get the upload URL. Please try again.");
                    return;
                }
            }

            const body = {
                documentId: documentList?.documentId,
                description: response,
                fileName: fileUrl,
                conversationId: documentList._id
            };
            await dispatch(questionReply(body));
            setResponse('');
            setFile(null); // Clear the file after upload
            message.success('Reply sent successfully');
            fetchMessages();
        } catch (error) {
            message.error('Failed to send reply');
        } finally {
            setLoading(false);
        }
    };

    const handleMarkAsResolved = async () => {
        setLoading(true);
        try {
            const body = { documentId: documentList?.documentId, conversationId: documentList._id };
            await dispatch(questionResolve(body));
            message.success('Marked as resolved');
            fetchMessages();
        } catch (error) {
            message.error('Failed to mark as resolved');
        } finally {
            setLoading(false);
        }
    };

    const deleteEntireThread = async () => {
        setLoading(true);
        try {
            const body = { documentId: documentList?.documentId, conversationId: documentList._id };
            await dispatch(questionDeleteApi(body));
            message.success('Thread deleted successfully');
            onClose();
        } catch (error) {
            message.error('Failed to delete thread');
        } finally {
            setLoading(false);
        }
    };

    const deleteSpecificEntry = async (id) => {
        setLoading(true);
        try {
            const body = { conversationId: id };
            await dispatch(questionEntryDeleteApi(body));
            setMessages(messages.filter(message => message._id !== id));
            message.success('Entry deleted successfully');
            fetchMessages();
        } catch (error) {
            message.error('Failed to delete entry');
        } finally {
            setLoading(false);
        }
    };

    const renderMessage = (message) => (
        <Card key={message._id} style={{ marginBottom: 16, position: 'relative' }}>
            <Button
                type="link"
                danger
                icon={<DeleteOutlined />}
                onClick={() => deleteSpecificEntry(message._id)}
                style={{ position: 'absolute', top: 8, right: 8 }}
            />
            <Space align="start">
                <Avatar>{message?.userId?.avatar}</Avatar>
                <div>
                    <div style={{ fontWeight: 'bold' }}>{message?.userId?.username} <span className='ml-2 py-1 px-2 rounded-lg bg-blue-200'>{message.status}</span></div>
                    <div style={{ color: 'gray', fontSize: 12 }}>{new Date(message.createdAt).toLocaleString()}</div>
                    <div style={{ marginTop: 8, width: '350px' }}>{message.description}</div>
                    {message.fileName && (
                        <FileAttachment
                            name={message.fileName}
                            size={message.fileSize}
                            type="file"
                        />
                    )}
                </div>
            </Space>
        </Card>
    );

    const handleFileChange = ({ file }) => {
        setFile(file);
        console.log(file)
    };

    return (
        <Drawer
            title={<span style={{ fontSize: '20px' }}>Discussion Thread</span>}
            placement="right"
            closable={false}
            open={drawerOpen}
            onClose={handleCancel}
            width={600}
        >
            <button className='absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all' onClick={handleCancel}>
                <CloseButton />
            </button>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
                <Button type="primary" danger onClick={deleteEntireThread} icon={<DeleteOutlined />}>Delete Thread</Button>
            </div>

            {loading ? (
                <div style={{ textAlign: 'center', padding: '50px 0' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <>
                    {messages.map(renderMessage)}

                    <Card style={{ marginTop: 16 }}>
                        <h3>Response</h3>
                        <TextArea
                            placeholder="Write your response..."
                            value={response}
                            onChange={handleResponseChange}
                            rows={4}
                        />
                        <Upload
                            style={{ width: '100%' }}
                            beforeUpload={() => false} // Prevent auto upload
                            onChange={handleFileChange}
                        >
                            <Button icon={<PlusOutlined />} className='w-[fit-content] m-[5px] mt-[15px]'>
                                Attach a file
                            </Button>
                        </Upload>
                        {file && (
                            <FileAttachment
                                name={file.name}
                                size={`${(file.size / 1024).toFixed(2)} KB`}
                                type={file.type}
                            />
                        )}
                        <div style={{ marginTop: 16, display: 'flex', justifyContent: 'space-between' }}>
                            <SecondaryButton text="Cancel" onClick={handleCancel} size='Medium'/>
                            <PrimaryButton text="Reply" onClick={handleReply} size='Medium'/>
                        </div>
                        <Button
                            type="link"
                            onClick={handleMarkAsResolved}
                            style={{ marginTop: '10px' }}
                        >
                            Mark as Resolved
                        </Button>
                    </Card>
                </>
            )}
        </Drawer>
    );
};

export default DiscussionThreadDrawer;
