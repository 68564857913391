
import React, { useState, useEffect } from "react";
import { Modal, Input, notification } from "antd";
import CloseButton from "../../SVG/CloseButtonSVG";
import SecondaryButton from "../../Buttons/SecondaryButton";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { renameFileApi, updateArchiveStatusApi, updateLockStatusApi } from "../../../features/dataRoomSlice";
import TextMedium from "../../TypographyDescriptions/TextMedium";
import ModalSection from "./ModalSection";

const ArchiveFolderModal = ({ isOpen, onClose, documentList, archive }) => {
    const [modalOpen, setModalOpen] = useState(isOpen);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    const handleOk = () => {
        setModalOpen(false);
        onClose();
    };

    const handleCancel = () => {
        setModalOpen(false);
    };

    const handleLock = async () => {
        setLoading(true)
        const body = {
            documentId: documentList?._id ? documentList?._id : documentList,
            archiveStatus: archive
        }

        await dispatch(updateArchiveStatusApi(body)).then((response) => {
            if (response?.meta.requestStatus === "fulfilled") {
                notification.info({
                    message: "Success",
                    description: archive ? "Folder Successfully Archived" : "Folder Successfully Unarchived"
                })
            } else {
                notification.error({
                    message: "Error",
                    description: archive ? "Folder archiving failed" : "Folder Unarchiving Failed"
                })
            }
            setLoading(false)
            handleOk();
            setIsModalVisible(true);
        })
    }

    return (
        <>
        {isModalVisible ? (
            <ModalSection title="Info" closeHandler={() => { setModalOpen(false); handleCancel(); }} text={archive ? "Success! The Folder has been archived and is now visible in Archived Folder" : "Success! THe Folder hass been unarchived and is now visinle in My Files."}/>
        ) : null}
        <Modal
            title={<span style={{ fontSize: "20px" }}>{archive ? "Archive Folder" : "Unarchive Folder"}</span>}
            centered
            open={modalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <button
                className="absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all"
                onClick={handleCancel}
            >
                <CloseButton />
            </button>
            <div className="flex flex-col gap-3 ">
                <div>
                    {archive ? (
                        <>
                            <TextMedium fontWeight={500} text={"Once archived, it can be found in 'My Archived Folder' but wont be accessible in 'My Files' "} display="block" />
                            <TextMedium fontWeight={500} text={"Are you sure you want to archive this folder ?"} display="block" />
                        </>
                    ) : (
                        <>
                            <TextMedium fontWeight={500} text={"Unarchiving this folder will restore it to 'My Files' and make it accessible for editing."} display="block" />
                            <TextMedium fontWeight={500} text={"Are you sure you want to proceed?"} display="block" />
                        </>
                    )}
                </div>
                <div className=" w-full flex flex-row justify-between mt-3 ">
                    <div>
                        <SecondaryButton text={"Cancel"} size="Medium" isDisabled={loading} />
                    </div>
                    <div>
                        <PrimaryButton text={archive ? "Archive" : "Unarchive"} size="Medium" onClick={handleLock} isLoading={loading} isDisabled={loading} />
                    </div>
                </div>
            </div>
        </Modal>
        </>
    );
};

export default ArchiveFolderModal;


