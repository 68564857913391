import React, { useState, useEffect } from "react";
import { Modal, Input, notification } from "antd";
import CloseButton from "../../SVG/CloseButtonSVG";
import SecondaryButton from "../../Buttons/SecondaryButton";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { renameFileApi, updateArchiveStatusApi, updateLockStatusApi } from "../../../features/dataRoomSlice";
import ModalSection from "./ModalSection";

const ArchiveFileModal = ({ isOpen, onClose, documentList, archive }) => {
    const [modalOpen, setModalOpen] = useState(isOpen);
    const [modalTitle, setModalTitle] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    const handleOk = () => {
        setModalOpen(false);
        onClose();
    };

    const handleCancel = () => {
        setModalOpen(false);
    };

    const handleLock = async () => {
        setLoading(true)
        const body = {
            documentId: documentList._id,
            archiveStatus: archive
        }

        await dispatch(updateArchiveStatusApi(body)).then((response) => {
            if (response?.meta.requestStatus === "fulfilled") {
                notification.info({
                    message: "Success",
                    description: archive ? "File Successfully Archived" : "File Successfully Unarchived"
                })
            } else {
                notification.error({
                    message: "Error",
                    description: archive ? "File archiving failed" : "File Unarchiving Failed"
                })
            }
            setLoading(false)
            handleOk();
            setIsModalVisible(true);
        })
    }

    return (
        <>
            {isModalVisible ? (
                <ModalSection title="Info" closeHandler={() => { setModalOpen(false); handleCancel(); }} text={archive ? "Success! The file has been archived and is now visible in Archived Files" : "Sucess! The file has been unarchived and is now visible in My Files."}/>
            ) : null}
            <Modal
                title={<span style={{ fontSize: "20px" }}>{archive ? "Archive File" : "Unarchive File"}</span>}
                centered
                open={modalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <button
                    className="absolute top-4 right-3 cursor-pointer opacity-70 hover:opacity-100 transition-all"
                    onClick={handleCancel}
                >
                    <CloseButton />
                </button>
                <div className=" px-2 flex flex-col gap-3 ">
                    <div>
                        {archive ? (
                            <>
                            {"This will archive the file you can access the saem in archive file section."}
                            </>
                        ) : (
                            <>
                            {"This action willl unarchive the file are u sure abou it?"}
                            </>
                        )}
                    </div>
                    <div className=" w-full flex flex-row justify-between mt-3 ">
                        <div>
                            <SecondaryButton text={"Cancel"} size="Medium" isDisabled={loading} />
                        </div>
                        <div>
                            <PrimaryButton text={archive ? "Archive" : "Unarchive"} size="Medium" onClick={handleLock} isLoading={loading} isDisabled={loading} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ArchiveFileModal;


